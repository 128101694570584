import React from "react"
import { navigate, useStaticQuery, graphql } from "gatsby"
import {
  useCourseStore,
  getCourseNavigation,
  getCourseProgress,
} from "../../../../../store/courses"
import LayoutCourse from "../../../../../layouts/course"
import Seo from "../../../../../components/seo"
import Heading from "../../../../../components/heading"
import Grid from "../../../../../components/grid"
import Stack from "../../../../../components/stack"
import CourseFooterNext from "../../../../../components/course-footer-next"
import Note from "../../../../../components/note"
import Paragraph from "../../../../../components/paragraph"
import Checkbox from "../../../../../components/checkbox"
import Input from "../../../../../components/input"
import Image from "../../../../../components/image"

const taskProps = {
  courseId: "hoelderlins-reisen",
  chapterId: "01-friedrich-hoelderlin",
  taskId: "wie-sah-hoelderlin-aus",
}

const Page = () => {
  const { submitForm } = useCourseStore()
  const navigation = getCourseNavigation(taskProps)
  const progress = getCourseProgress(taskProps)

  const data = useStaticQuery(graphql`
    query WieSahHoelderlinQuery {
      portrait: file(relativePath: { eq: "kurse/portrait-hoelderlin.png" }) {
        ...mediumImage
      }
    }
  `)

  return (
    <LayoutCourse
      as="form"
      onSubmit={(event) => {
        event.preventDefault()
        submitForm({
          event,
          ...taskProps,
        })
        navigate(
          "/kurse/hoelderlins-reisen/01-friedrich-hoelderlin/wie-sah-hoelderlin-aus/beschreibung"
        )
      }}
      navigation={navigation}
      progress={progress}
      footer={<CourseFooterNext type="submit" value="submit" />}
    >
      <Seo title="Wie sah Hölderlin aus? " />
      <Stack>
        <Stack space={3}>
          <Heading as="h1" level={2}>
            Wie sah Hölderlin aus?
          </Heading>
        </Stack>
        <Paragraph>
          Fotos gab es zu Hölderlins Zeit noch nicht. Deshalb gibt es von ihm
          nur sehr wenig Bilder. Wir haben aber Glück: Es gibt eines, das ihn
          als 18-jährigen Schüler zeigt. Gemalt hat es sein damaliger bester
          Freund Immanuel Nast.
        </Paragraph>
        <Grid columns={[1, 2]}>
          <Image
            image={data.portrait.childImageSharp.gatsbyImageData}
            alt="Porträt"
            caption="So sah Friedrich Hölderlin als Jugendlicher aus."
          />
          <Stack>
            <Note variant="task">
              Wie wirkt Hölderlin in diesem Bild auf dich? Wähle passende
              Adjektive aus, indem du sie anklickst.
            </Note>
            <Grid>
              <Stack space={6}>
                <Stack space={3}>
                  <Checkbox name="q-01" value="schüchtern">
                    schüchtern
                  </Checkbox>
                  <Checkbox name="q-02" value="hübsch">
                    hübsch
                  </Checkbox>
                  <Checkbox name="q-03" value="merkwürdig">
                    merkwürdig
                  </Checkbox>
                  <Checkbox name="q-04" value="unfreundlich">
                    unfreundlich
                  </Checkbox>
                  <Checkbox name="q-05" value="klug">
                    klug
                  </Checkbox>
                  <Checkbox name="q-06" value="eitel">
                    eitel
                  </Checkbox>
                  <Checkbox name="q-07" value="jung">
                    jung
                  </Checkbox>
                  <Checkbox name="q-08" value="erwachsen">
                    erwachsen
                  </Checkbox>
                  <Checkbox name="q-09" value="nachdenklich">
                    nachdenklich
                  </Checkbox>
                </Stack>
              </Stack>
              <Stack space={6}>
                <Stack space={3}>
                  <Checkbox name="q-10" value="elegant">
                    elegant
                  </Checkbox>
                  <Checkbox name="q-11" value="verkleidet">
                    verkleidet
                  </Checkbox>
                  <Checkbox name="q-12" value="brav">
                    brav
                  </Checkbox>
                  <Checkbox name="q-13" value="stur">
                    stur
                  </Checkbox>
                  <Checkbox name="q-14" value="frech">
                    frech
                  </Checkbox>
                  <Checkbox name="q-15" value="zierlich">
                    zierlich
                  </Checkbox>
                  <Checkbox name="q-16" value="ungewöhnlich">
                    ungewöhnlich
                  </Checkbox>
                  <Checkbox name="q-17" value="ungepflegt">
                    ungepflegt
                  </Checkbox>
                </Stack>
              </Stack>
            </Grid>
            <Input
              id="q-18"
              name="q-18"
              label="Fällt dir noch etwas auf?"
              placeholder="Hier ist Platz für dein Wort"
            />
          </Stack>
        </Grid>
      </Stack>
    </LayoutCourse>
  )
}

export default Page
